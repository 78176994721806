.container {
	font-size: 16px;
	color: #444;
	letter-spacing: -0.04ch;
	margin-left: 4px;
	margin-top: -7px;
}

.darkmode {
	color: #AEAEAE;
}
