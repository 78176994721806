.container {
	background-color: #f7f7f7;
	color: #151515;
	border: solid 1px #999;
	box-shadow: 0px 0px 5px #aaa;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

@media screen and (min-width: 768px) {
	.container {
		height: 60px;
		width: 60px;
		line-height: 60px;
		right: 50px;
		bottom: 50px;
		border-radius: 35px;
		font-size: 30px;
		margin-top: 15px;
	}

	.container:hover {
		font-size: 35px;
		transition: font-size 0.1s ease 0s;
	}
}

@media screen and (max-width: 767px) {
	.container {
		height: 40px;
		width: 40px;
		line-height: 40px;
		right: 30px;
		bottom: 30px;
		border-radius: 35px;
		font-size: 20px;
		margin-top: 10px;
	}

	.container:hover {
		font-size: 25px;
		transition: font-size 0.1s ease 0s;
	}
}

.container:hover {
	cursor: pointer;
}

.darkmode {
	background-color: #1a1a1a;
	color: #eee;
}
