li {
	list-style-type: '✔';
	padding-left: 15px;
	margin-left: 15px;
}

a:visited {
	text-decoration: none;
}

ul {
	margin-top: 5px;
}

ul > ul {
	margin-top: 0;
}
