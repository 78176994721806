.container {
	position: fixed;
}

@media screen and (min-width: 768px) {
	.container {
		right: 50px;
		bottom: 50px;
	}
}

@media screen and (max-width: 767px) {
	.container {
		right: 30px;
		bottom: 30px;
	}
}
